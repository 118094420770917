import React, { useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const FormBlock = () => {
  const AnswerBy = {
    Phone: 'Phone',
    Email: 'Email',
    Mail: 'Mail',
  };

  const AppealType = {
    Offer: 'Offer',
    Complaint: 'Complaint',
    Gratitude: 'Gratitude',
  };

  const CustomerType = {
    IndividualCustomer: 'IndividualCustomer',
    LegalCustomer: 'LegalCustomer',
  };
  const [successMessage, setSuccessMessage] = useState('');
  const [formData, setFormData] = useState({
    customerType: CustomerType.IndividualCustomer,
    appealType: AppealType.Offer,
    fullName: '',
    answersPhoneNumber: '',
    answersEmail: '',
    currentResidence: '',
    getAnswerBy: AnswerBy.Email,
    content: '',
    email: '',
    mailingAddress: '',
    phoneNumber: '',
  });

  const clearFormData = () => {
    setFormData({
      customerType: CustomerType.IndividualCustomer,
      appealType: AppealType.Offer,
      fullName: '',
      answersPhoneNumber: '',
      answersEmail: '',
      currentResidence: '',
      getAnswerBy: AnswerBy.Email,
      content: '',
      email: '',
      mailingAddress: '',
      phoneNumber: '',
    });
  };

  const handleChange = e => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? (checked ? value : '') : value;

    if (name === 'getAnswerBy' && checked) {
      setFormData(prevState => ({
        ...prevState,
        [name]: value,
        email: value === AnswerBy.Email ? '' : formData.email,
        mailingAddress: value === AnswerBy.Mail ? '' : formData.mailingAddress,
        phoneNumber: value === AnswerBy.Phone ? '' : formData.phoneNumber,
      }));
    } else {
      const updatedFormData = { ...formData, [name]: newValue };
      Object.keys(updatedFormData).forEach(key => {
        if (updatedFormData[key] === '') {
          delete updatedFormData[key];
        }
      });
      setFormData(updatedFormData);
    }
  };
  const { t } = useTranslation();

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_COMPLAINT_FORM}/guest/complaint`, formData);
      setSuccessMessage(t('block11.success'));

      clearFormData();

      // Запуск таймера для скрытия сообщения об успехе через 5 секунд
      setTimeout(() => {
        setSuccessMessage('');
      }, 5000);
    } catch (error) {
      console.error(t('block11.error'), error);
    }
  };

  return (
    <section className="formBlock">
      <div className="container">
        <h2 className="formBlock__title">{t('block11.title')}</h2>
        <p className="formBlock__subtitle">
          {t('block11.subtitle')}
        </p>
        <form className="formBlock__form" method="POST" onSubmit={handleSubmit}>
          <div className="first">
            <div className="col-6 space-between">
              <fieldset className="formBlock__form__radio customerType">
                <h3>{t('block11.formRadioLabel')}</h3>
                <input
                  type="radio"
                  name="customerType"
                  id={CustomerType.IndividualCustomer}
                  value={CustomerType.IndividualCustomer}
                  checked={formData.customerType === CustomerType.IndividualCustomer}
                  onChange={handleChange}
                  required
                />
                <label htmlFor={CustomerType.IndividualCustomer}>{t('block11.formRadioInput1')}</label>
                <input
                  type="radio"
                  name="customerType"
                  id={CustomerType.LegalCustomer}
                  value={CustomerType.LegalCustomer}
                  checked={formData.customerType === CustomerType.LegalCustomer}
                  onChange={handleChange}
                  required
                />
                <label htmlFor={CustomerType.LegalCustomer}>{t('block11.formRadioInput2')}</label>
              </fieldset>
              <div className=" formBlock__form__radio appealType">
                <h2>{t('block11.formRadioFieldTitle')}*</h2>
                <div>
                  <input
                    type="radio"
                    name="appealType"
                    id={AppealType.Complaint}
                    value={AppealType.Complaint}
                    checked={formData.appealType === AppealType.Complaint}
                    onChange={handleChange}
                    required
                  />
                  <label htmlFor={AppealType.Complaint}>{t('block11.formRadioFieldTitle1')}</label>
                  <input
                    type="radio"
                    name="appealType"
                    id={AppealType.Offer}
                    value={AppealType.Offer}
                    checked={formData.appealType === AppealType.Offer}
                    onChange={handleChange}
                    required
                  />
                  <label htmlFor={AppealType.Offer}>{t('block11.formRadioFieldTitle2')}</label>
                  <input
                    type="radio"
                    name="appealType"
                    id={AppealType.Gratitude}
                    value={AppealType.Gratitude}
                    checked={formData.appealType === AppealType.Gratitude}
                    onChange={handleChange}
                    required
                  />
                  <label htmlFor={AppealType.Gratitude}>{t('block11.formRadioFieldTitle3')}</label>
                </div>
              </div>
            </div>
            <textarea
              className="col-6"
              placeholder={t('block11.formTextarea')}
              cols="49"
              rows="4"
              name="content"
              value={formData.content}
              onChange={handleChange}
              required
              minLength="20"
              maxLength="10000"></textarea>
          </div>
          <div className="first">
            <div className="col-6">
              <div className="formBlock__form__input ">
                <input
                  type="text"
                  className="formBlock__input"
                  placeholder={t('block11.formRadioFieldInput1')}
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleChange}
                  required
                  minLength="5"
                  maxLength="256"
                  pattern="[A-Za-zА-Яа-яЁё]+\s[A-Za-zА-Яа-яЁё]+"
                  title={t('block11.formErrorTitle1')}
                />
                <input
                  type="tel"
                  className="formBlock__input"
                  placeholder={t('block11.formRadioFieldInput2')}
                  name="answersPhoneNumber"
                  value={formData.answersPhoneNumber}
                  onChange={handleChange}
                  required
                  minLength="12"
                  maxLength="12"
                  pattern="996[0-9]{9}"
                  title={t('block11.formErrorTitle3')}
                />
                <input
                  type="email"
                  className="formBlock__input"
                  placeholder={t('block11.formRadioFieldInput3')}
                  title={t('block11.formErrorTitle2')}
                  pattern=".+@.+\..+"
                  name="answersEmail"
                  value={formData.answersEmail}
                  onChange={handleChange}
                  required
                  minLength="5"
                  maxLength="100"
                />
                <input
                  type="text"
                  className="formBlock__input"
                  placeholder={t('block11.formRadioFieldInput4')}
                  name="currentResidence"
                  value={formData.currentResidence}
                  onChange={handleChange}
                  required
                  minLength="5"
                  maxLength="256"
                />
              </div>
            </div>

            <div className="col-6">
              <p>* {t('block11.formParagraph1')}:</p>
              <p>&emsp;- {t('block11.formParagraph2')}</p>
              <p>&emsp;- {t('block11.formParagraph3')}</p>
              <p>&emsp;&nbsp;&nbsp;&nbsp;{t('block11.formParagraph4')}</p>
              <p>&emsp;- {t('block11.formParagraph5')}</p>
              <p>&emsp;- {t('block11.formParagraph6')}</p>
              <p>&emsp;- {t('block11.formParagraph7')}</p>
              <br />
              <p>** {t('block11.formParagraph8')}</p>
              <p>{t('block11.formParagraph9')}</p>
              <p>{t('block11.formParagraph10')}</p>
              <p>{t('block11.formParagraph11')}</p>
            </div>
          </div>

          <div className="first">
            <div className="col-5 formBlock__form__checkbox customerType first second">
              <input
                type="radio"
                id={AnswerBy.Email}
                name="getAnswerBy"
                value={AnswerBy.Email}
                checked={formData.getAnswerBy === AnswerBy.Email}
                onChange={handleChange}
                required
              />
              <label htmlFor={AnswerBy.Email}>{t('block11.formRadioFieldInput5')}:</label>
              <input
                type="radio"
                id={AnswerBy.Mail}
                name="getAnswerBy"
                value={AnswerBy.Mail}
                checked={formData.getAnswerBy === AnswerBy.Mail}
                onChange={handleChange}
                required
              />
              <label htmlFor={AnswerBy.Mail}>{t('block11.formRadioFieldInput6')}:</label>
              <input
                type="radio"
                id={AnswerBy.Phone}
                name="getAnswerBy"
                value={AnswerBy.Phone}
                checked={formData.getAnswerBy === AnswerBy.Phone}
                onChange={handleChange}
                required
              />
              <label htmlFor={AnswerBy.Phone}>{t('block11.formRadioFieldInput7')}:</label>
            </div>
            <div className="col-7">
              {formData.getAnswerBy === AnswerBy.Email && (
                <input
                  type="email"
                  className="formBlock__input"
                  placeholder={t('block11.formInputLabel1')}
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  minLength="5"
                  maxLength="100"
                  pattern=".+@.+\..+"
                  title={t('block11.formErrorTitle2')}
                />
              )}
              {formData.getAnswerBy === AnswerBy.Mail && (
                <input
                  type="text"
                  className="formBlock__input"
                  placeholder={t('block11.formInputLabel2')}
                  name="mailingAddress"
                  value={formData.mailingAddress}
                  onChange={handleChange}
                  required
                  minLength="5"
                  maxLength="256"
                />
              )}
              {formData.getAnswerBy === AnswerBy.Phone && (
                <input
                  type="tel"
                  className="formBlock__input"
                  placeholder={t('block11.formInputLabel3')}
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  required
                  minLength="12"
                  maxLength="12"
                  pattern="996[0-9]{9}"
                  title={t('block11.formErrorTitle3')}
                />
              )}
            </div>
          </div>

          <button className="formBlock__btn" type="submit">
            {t('block11.button')}
          </button>
        </form>

        {successMessage && <p className="text-success">{successMessage}</p>}
      </div>
    </section>
  );
};

export default FormBlock;
